<template>
  <div class="app-container">
    <div class="top">
      <el-tag>
        {{ name }}
      </el-tag>
    </div>
    <el-card class="box-card" v-for="item in form" :key="item.id">
      <div class="class_box">
        {{ item.name }}
        <!-- <el-time-picker
          is-range
          v-model="item.date"
          range-separator="至"
          format="HH:mm"
          value-format="HH:mm:[00]"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          style="margin-left: 10px"
          @input="changeDate(item.date, key)"
        > 
        </el-time-picker> -->
        <div style="margin-left: 20px">
          开始时间:
          <el-time-picker
            v-model="item.start_at"
            format="HH:mm"
            value-format="HH:mm:[00]"
            placeholder="开始时间"
            style="margin-left: 10px"
          >
          </el-time-picker>
        </div>
        <div style="margin-left: 20px">
          结束时间:
          <el-time-picker
            v-model="item.end_at"
            format="HH:mm"
            value-format="HH:mm:[00]"
            placeholder="结束时间"
            style="margin-left: 10px"
          >
          </el-time-picker>
        </div>
      </div>
    </el-card>
    <div class="btn" style="margin-top: 20px">
      <el-button type="primary" @click="saveData">保存</el-button>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      form: [
        {
          id: null,
          name: "第一节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第二节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第三节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第四节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第五节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第六节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第七节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第八节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第九节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第十节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第十一节",
          start_at: "",
          end_at: "",
          date: [],
        },
        {
          id: null,
          name: "第十二节",
          start_at: "",
          end_at: "",
          date: [],
        },
      ],
      campusId: null,
      name: "",
    };
  },
  created() {
    this.campusId = this.$route.query.id;
    this.name = this.$route.query.name;
    this.getList();
  },
  methods: {
    changeDate(e, key) {
      this.form[key].date = e;
      this.$forceUpdate(); //强制更新
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/school/campusClassTimeConfig",
        method: "get",
        params: {
          campus_id: this.campusId,
        },
      }).then((response) => {
        this.form = response.data;
        this.form.forEach((e, i) => {
          if (e.start_at) {
            this.form[i].date = [e.start_at, e.end_at];
          }
        });
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    saveData() {
      // this.form.forEach((item, key) => {
      //   if (item.date) {
      //     item.start_at = item.date[0];
      //     item.end_at = item.date[1];
      //   }
      //   delete this.form[key].date;
      // });
      for (let index = 0; index < this.form.length; index++) {
        const item = this.form[index];
        if (item.end_at < item.start_at) {
          this.$message.error("第" + (index+1) + "节课时间有误!");
          return;
        }
        if (!item.end_at && !item.start_at) {
          this.$message.error("请完善所有资料后提交!");
          return;
        }
        delete this.form[index].date;
      }
      this.btnLoading = true;
      request({
        url: "/api/schoolend/school/campusClassTimeConfigStore",
        method: "post",
        data: {
          campus_id: this.campusId,
          configs: this.form,
        },
      })
        .then((response) => {
          this.btnLoading = false;
          this.dialogFormVisible = false;
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.getList();
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.class_box {
  display: flex;
  align-items: center;
  padding: 10px;
  // margin-bottom: 10px;
}
.box-card {
  margin-bottom: 10px;
}
.el-tag {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 5px 20px;
  height: 40px;
}
</style>
